import axios from "axios";

export function fetchGames() {
  let encodedURI = window.encodeURI("/api/v2/games");

  return axios.get(encodedURI).then(function (response) {
    return response.data;
  });
}

export function fetchGroupInfo({ groupId, headers }) {
  let encodedURI = window.encodeURI("/api/v2/groups/" + groupId + "/show_public");

  return axios({
    method: "get",
    url: encodedURI,
    headers: headers,
  });
}

export function updateGroupDescription({ groupId, description, headers }) {
  console.log(" in updateGroupDescription");
  let encodedURI = window.encodeURI("/groups/" + groupId);

  return axios({
    method: "put",
    url: encodedURI,
    headers: headers,
    data: {
      description: description,
    },
  });
}

export function fetchApprovedGames({ groupId }) {
  let encodedURI = window.encodeURI("/api/v2/groups/" + groupId + "/approved_games");

  return axios.get(encodedURI).then(function (response) {
    return response.data;
  });
}

export function fetchUserApprovedGames({ userId }) {
  let encodedURI = window.encodeURI("/api/v2/users/" + userId + "/approved_games");

  return axios.get(encodedURI).then(function (response) {
    return response.data;
  });
}

export function fetchPlatformMemberships({ groupId }) {
  let encodedURI = window.encodeURI("/api/v2/groups/" + groupId + "/platform_memberships");

  return axios.get(encodedURI).then(function (response) {
    return response.data;
  });
}

export function updateApprovedGames({ userId, groupId, gameId, headers }) {
  let encodedURI = "";

  if (userId) {
    encodedURI = window.encodeURI("/approved_games");
  } else {
    encodedURI = window.encodeURI("/approved_games");
  }

  console.log("encodedUri: ", encodedURI);

  return axios({
    method: "post",
    url: encodedURI,
    headers: headers,
    data: {
      user_id: userId,
      group_id: groupId,
      game_id: gameId,
    },
  });
}

export function createGroupManually({ gameId, headers }) {
  let encodedURI = window.encodeURI("/api/v2/groups");
  return axios({
    method: "post",
    url: encodedURI,
    headers: headers,
    data: {
      group: {
        game_id: gameId,
      },
    },
  });
}

export async function createGamingSession({ data, headers }) {
  const captcha_site_key = window.location.origin.includes("localhost")
    ? "6LdsBnwgAAAAAIrKTkM88QMUmP4W1iAJ5HHqWm_R"
    : "6LcvyOEgAAAAALTVVRPj5ZBtkkIbli5tDNLZ7lu_";

  const captcha_token = await grecaptcha.execute(captcha_site_key, { action: "submit" });
  data.captcha_token = captcha_token;

  let encodedURI = window.encodeURI("/api/v2/gaming_sessions");

  return axios({
    method: "post",
    url: encodedURI,
    headers: headers,
    data: data,
  });
}

export function updateGamingSession({ data, headers }) {
  let encodedURI = window.encodeURI("/api/v2/gaming_sessions/" + data.gaming_session_id);

  return axios({
    method: "put",
    url: encodedURI,
    headers: headers,
    data: data,
  });
}

export function updatePlatformMemberships({ groupId, platformId, headers }) {
  let encodedURI = window.encodeURI("/api/v2/platform_memberships");

  return axios({
    method: "post",
    url: encodedURI,
    headers: headers,
    data: {
      group_id: groupId,
      platform_id: platformId,
    },
  });
}

export function createTimeSlots({ groupId, groupName, groupTimeZone }) {
  let encodedURI = window.encodeURI("https://advancepass.net/events.json");

  return axios({
    method: "post",
    url: encodedURI,
    data: {
      group_id: groupId,
      name: groupName + " Player Availability",
      time_zone: groupTimeZone,
      auth_token: "H3idE9H73lX",
    },
  });
}

export function fetchTimeSlots({ groupId, groupName, timeZone, groupTimeZone }) {
  let encodedURI = window.encodeURI("https://advancepass.net/events/" + groupId + "/freetime.json");

  return axios({
    method: "get",
    url: encodedURI,
    params: {
      group_id: groupId,
      time_zone: timeZone,
      auth_token: "H3idE9H73lX",
    },
  }).then(function (response) {
    if (response.data.status == "unprocessable_entity") {
      return createTimeSlots({ groupId, groupName, groupTimeZone }).then(function (response) {
        return fetchTimeSlots({ groupId, groupName, timeZone });
      });
    } else {
      return response.data;
    }
  });
}

export function updateTimeSlot({ timeSlot, currentUserGamertag }) {
  let encodedURI = window.encodeURI("https://advancepass.net/events/" + timeSlot.id + "/mark_timeslot_available");

  return axios({
    method: "post",
    url: encodedURI,
    data: {
      id: timeSlot.id,
      username: currentUserGamertag,
      auth_token: "H3idE9H73lX",
    },
  });
}

export function fetchAllGroupTags({ suggestedOnly }) {
  let encodedURI = window.encodeURI("/api/v2/groups/all_tags?suggested_only=" + suggestedOnly);

  return axios.get(encodedURI).then(function (response) {
    return response.data;
  });
}

export function fetchAllUserTags({ suggestedOnly }) {
  let encodedURI = window.encodeURI("/api/v2/users/all_tags?suggested_only=" + suggestedOnly);

  return axios.get(encodedURI).then(function (response) {
    return response.data;
  });
}

export function updateGroupTags({ groupId, tag, headers }) {
  let encodedURI = window.encodeURI("/groups/" + groupId + "/add_tag");

  return axios({
    method: "post",
    url: encodedURI,
    headers: headers,
    data: {
      group_id: groupId,
      tag_name: tag,
    },
  });
}

export function fetchUserInfo({ userId, headers }) {
  let encodedURI = window.encodeURI("/api/v2/users/" + userId + "/show_public");

  return axios({
    method: "get",
    url: encodedURI,
    headers: headers,
  });
}

export function fetchUserFeedItems({ userId, headers }) {
  let encodedURI = window.encodeURI("/api/v2/users/" + userId + "/feed");

  return axios({
    method: "get",
    url: encodedURI,
    headers: headers,
  });
}

export function updateUserTags({ userId, tag, headers }) {
  let encodedURI = window.encodeURI("/api/v2/users/" + userId + "/add_tag");

  return axios({
    method: "post",
    url: encodedURI,
    headers: headers,
    data: {
      user_id: userId,
      tag_name: tag,
    },
  });
}

export async function createUser(data) {
  let encodedURI = window.encodeURI("/api/v2/users");

  return axios({
    method: "post",
    url: encodedURI,
    headers: data.headers,
    data: {
      user: data.body,
    },
  });
}

export function updateUser(data) {
  let encodedURI = window.encodeURI("/api/v2/users/" + data.body.user_id);
  return axios({
    method: "put",
    url: encodedURI,
    headers: data.headers,
    data: {
      user: data.body,
    },
  });
}

export function fetchUserDefaults({ userId }) {
  let encodedURI = window.encodeURI("/api/v2/groups/" + userId + "/approved_games");

  return axios.get(encodedURI).then(function (response) {
    return response.data;
  });
}

export function postUsernameMention(room, senderUsername, recipientUsername, text) {
  let config = {
    headers: {},
  };

  return axios.post(
    "/api/v2/chatrooms",
    {
      message: text,
      chatroom: room,
      sender_username: senderUsername,
      recipient_username: recipientUsername,
    },
    config
  );
}

export function postCharacter(character, confirmedSessionId) {
  let encodedURI = window.encodeURI("/api/v2/characters");
  return axios.post(encodedURI, {
    character: character,
    confirmed_session_id: confirmedSessionId,
  });
}

export function removeCharacter(confirmedSessionId, characterId) {
  if (confirmedSessionId) {
    let encodedURI = window.encodeURI("/api/v2/confirmed_sessions/" + confirmedSessionId + "/remove_character");
    return axios.post(encodedURI, {
      confirmed_session_id: confirmedSessionId,
    });
  } else {
    let encodedURI = window.encodeURI("/api/v2/characters/" + characterId);
    return axios.delete(encodedURI, {});
  }
}

export function postNewConversationMessage(conversationId, message) {
  let config = {
    headers: { Authorization: "Token token=" + process.env.PWNTASTIC_CHAT_KEY },
  };

  return axios.post(
    "/api/v2/chatrooms",
    {
      notification_type: "new-private-message",
      conversation_id: conversationId,
      message_text: message.text,
    },
    config
  );
}
