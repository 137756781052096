import React, { useState, useEffect } from 'react';
import * as api from "../../utils/api";



function UserTags(props) {
  const [loading, setLoading] = useState(true)
  const [editing, setEditing] = useState(false)
  const [allTags, setTags] = useState(null)
  const [userTags, setUserTags] = useState(null)
  const [customTag, setCustomTag] = useState("")


  useEffect(() => {
    fetchAllTags();
  }, []);

  useEffect(() => {
    fetchUserTags();
  }, []);

  const fetchAllTags = async () => {
    const response = await api.fetchAllUserTags({ suggestedOnly: false })
    setTags(response)
  };

  const fetchUserTags = async () => {
    setLoading(true)
    const response = await api.fetchUserInfo({ userId: props.userId, headers: ReactOnRails.authenticityHeaders() })
    setUserTags(response.data.tags_cache)
    setLoading(false)
  };


  const updateTags = (tag) => {
    if (userTags.includes(tag)) {
      setUserTags(userTags.filter(i => i !== tag))
    } else {
      setUserTags([...userTags, tag])
    }

    api.updateUserTags({ userId: props.userId, tag: tag, headers: ReactOnRails.authenticityHeaders() }).then((response) => {
      setCustomTag("")
    })
  };

  const onKeyPress = (event) => {
    if (event.charCode === 13) {
      updateTags(customTag)
    }
  }

  const onSubmit = () => {
    if (customTag) {
      updateTags(customTag)
    }
    setEditing(!editing)
  }

  if (loading) {
    return (
      <div className="gap-xy-1">...</div>
    )
  }

  if (props.isAuthorized && editing) {

    return (
      <div>
        <div className="gap-xy-1">
          {allTags.map(tag => (
            < button type="button" className={"btn btn-xs btn-glass " + (userTags.includes(tag) ? 'btn-primary' : 'btn-outline-primary')} onClick={() => updateTags(tag)} key={tag}>{tag}</button>
          )
          )
          }

          {userTags.filter(tag => (
            !allTags.includes(tag))).map(tag => (
              < button type="button" className={"btn btn-xs btn-glass " + (userTags.includes(tag) ? 'btn-primary' : 'btn-outline-primary')} onClick={() => updateTags(tag)} key={tag}>{tag}</button>

            )
            )
          }
        </div>

        <div class="mt-2" style={{ display: "flex" }}>
          <input className={"mr-2 form-control text-dark" + (customTag.length >= 60 && " is-invalid")} type="text" style={{ width: "200px" }} value={customTag} placeholder="custom tag" onChange={event => setCustomTag(event.target.value)} maxLength="60" onKeyPress={(event) => onKeyPress(event)} />
          < button type="button" className={"btn btn-glass btn-success ml-2"} onClick={() => onSubmit()} >done</button>

        </div>
      </div>
    )

  } else {

    return (
      <div className="gap-xy-1">
        {userTags.map(tag => (
          < span className={"badge badge-dark"} key={tag}>{tag}</span>
        ))
        }

        {props.isAuthorized ? (
          < button type="button" className={"badge badge-dark"} style={{ borderWidth: 0, cursor: "pointer" }} onClick={() => setEditing(!editing)} ><i className="fa fa-plus"></i></button>
        ) : null}
      </div>
    )
  }

}

export default UserTags;